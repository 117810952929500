<template>
  <div class="layout-header" :class="[{ 'is-active-search': isSearch }]" v-click-outside="closeSearch">
    <div class="top">
      <ul class="hidden menu lg:flex">
        <slot name="menu"></slot>
      </ul>
      <div v-if="width>768" class="phonetop">
        <a :class="[arVariableSite.CLASS_TEL]" @click="reachGool('phoneNumber_link_click'); reachGool2('phone_click',false);" :href="'tel:'+arVariableSite.TEL_CITY_VIEW_NUM" class="s-22 w-600 phone">{{ arVariableSite.TEL_CITY_VIEW }}</a>
<!--        <a class="requestcall" @click="requestcall = true">Заказать звонок</a>-->
      </div>
      <div class="city">
        <icon-map></icon-map>
        <div class="s-14 w-500">{{ arVariableSite.CITY_VIEW }}</div>
        <!--  -->
        <div class="cities-popup-block">
          <div class="cities-popup ui-scroll">
            <div
                :class="[{ active: arCity.name === arVariableSite.CITY_VIEW }]"
                class="city-name s-14 w-500"
                v-for="(arCity, index) in cities"
                :key="index"
                @click="changeCity()"
            >
              <a :href="urls(arCity)"> {{ arCity.name }} </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="width<769" class="phonetop-mobile">
        <a :class="[arVariableSite.CLASS_TEL]" @click="reachGool('phoneNumber_link_click'); reachGool2('phone_click',false);" :href="'tel:'+arVariableSite.TEL_CITY_VIEW_NUM" class="s-16 w-600">{{ arVariableSite.TEL_CITY_VIEW }}</a>
<!--        <a class="requestcall" @click="requestcall = true">Заказать звонок</a>-->
      </div>
      <div class="s-14 w-500 cities"><a @click="reachGool('contacts_link_click'); reachGool2('address_click',false);" href="/contacts/">Адреса магазинов</a></div>
    </div>
    <div class="main">
      <span v-if="urlCurrent('/')" class="logo-wrapper">
        <ui-logo class="logo"></ui-logo>
      </span>
      <a v-else href="/" class="logo-wrapper">
        <ui-logo class="logo"></ui-logo>
      </a>
      <div class="search-button button" :class="[{ active: isSearch }, { 'pretty-button': !isSearch }]">
        <input
            @keypress.enter="goToSearchResults"
            v-bind:value="searchQuery"
            @input="updateSearch($event.target.value)"
            placeholder="Начните искать..."
            autocomplete="off"
            autofocus
            type="text"
            v-if="isSearch"
        />
        <icon-search @click.native="openSearch"></icon-search>
        <layout-search-suggestions-window
            :closeSearch="closeSearch"
            :searchQuery="searchQuery"
            :onSelectSuggestion="onSelectSuggestion"
            :suggestions="suggestions"
            :products="products"
            v-if="isSearch && searchQuery && (suggestions.length || products.length)"
        ></layout-search-suggestions-window>
      </div>
      <layout-main-menu v-if="!isSearch" @close="isMenuOpen = false" :class="[{ open: isMenuOpen }]" :armainmenu="armainmenu"></layout-main-menu>
      <slot v-if="isSearch && isMobile ? false : true" name="buttons"></slot>
      <div v-if="isSearch && isMobile ? false : true" @click="isMenuOpen = true" class="menu-button">
        <div @click="isMenuOpen = true" class="menu-button">
          <icon-menu></icon-menu>
        </div>
      </div>

      <span v-if="urlCurrent('/cart/')" title="Корзина" class="cart-button button pretty-button">
        <icon-bag></icon-bag>
        <div v-if="Number(countProduct)>0" class="ui-badge">{{ countProduct }}</div>
      </span>
      <a v-else @click="reachGool2('go_to_cart',false);" href="/cart/" title="Корзина" class="cart-button button pretty-button">
        <icon-bag></icon-bag>
        <div v-if="Number(countProduct)>0" class="ui-badge">{{ countProduct }}</div>
      </a>
      <span v-if="urlCurrent('/compare/')" title="Сравнение" class="side-button button pretty-button">
        <icon-side></icon-side>
        <div v-if="Number(countCompare)>0" class="ui-badge">{{ countCompare }}</div>
      </span>
      <a v-else href="/compare/" title="Сравнение" class="side-button button pretty-button">
        <icon-side></icon-side>
        <div v-if="Number(countCompare)>0" class="ui-badge">{{ countCompare }}</div>
      </a>
      <span v-if="urlCurrent('/favorites/')" title="Избранное" class="fav-button button pretty-button">
        <icon-heart></icon-heart>
        <div v-if="Number(countfavorite)>0" class="ui-badge">{{ countfavorite }}</div>
      </span>
      <a v-else href="/favorites/" title="Избранное" class="fav-button button pretty-button">
        <icon-heart></icon-heart>
        <div v-if="Number(countfavorite)>0" class="ui-badge">{{ countfavorite }}</div>
      </a>
    </div>

    <layout-popup v-model="requestcall" :sended="sended">
      <div class="s-30 w-500">Заказать звонок</div>
      <div class="s-16 opacity-80"></div>
      <template slot="form">
        <phone-mask-input
            v-model="phone"
            autoDetectCountry
            @onValidate="onValidate"
            inputClass="ui-input"
            placeholder=""
            required
        />
        <ui-button @click="sendFeedback('Заказать звонок' )" style="margin-top: 2em;">Оставить заявку</ui-button>
      </template>
      <div slot="suffix" class="s-12">
        Оставляя Ваш телефон, вы соглашаетесь с <a class="underline" href="/politica/" title="Политика обработки персональных данных">политикой обработки персональных данных</a>
      </div>
    </layout-popup>

  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  props: {
    armainmenu:{}
  },
  components: {
    iconMap: () => import('@/components/icon/icon-map'),
    iconMenu: () => import('@/components/icon/icon-menu'),
    iconSearch: () => import('@/components/icon/icon-search'),
    iconBag: () => import('@/components/icon/icon-bag'),
    iconSide: () => import('@/components/icon/icon-side'),
    iconHeart: () => import('@/components/icon/icon-heart'),
    uiLogo: () => import('@/components/ui/ui-logo'),
    layoutMainMenu: () => import('@/components/layout/layout-main-menu'),
    layoutSearchSuggestionsWindow: () => import('@/components/layout/search/layout-search-suggestions-window'),
    layoutHeaderCitiesPopup: () => import('@/components/layout/header/layout-header-cities-popup'),
    'layout-popup': () => import('@/components/layout/layout-popup'),
    'ui-button': () => import('@/components/ui/ui-button.vue'),
  },
  data (){
    return {
      city: "Москва",
      isMenuOpen: false,
      isSearch: false,
      searchQuery: '',
      suggestions: [],
      products: [],
      isMobile: false,
      countProduct: 0,
      countfavorite: 0,
      countCompare: 0,
      tim: 1,
      width: window.innerWidth,
      citiesPopupOpened: true,
      cities: this.$store.state.langs.ARCITY,
      siteurl: this.$store.state.langs.SITE_URL,
      isActive: false,
      arVariableSite: {},
      siteid: this.$store.state.siteid,
      activename: "",
      activename1: "",
      menublock: null,
      phone: "",
      sended: false,
      requestcall: false,
      validPhone: false,
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {
    this.popupItem = this.$el
    this.isMobile = window.innerWidth < 768
  },
  created() {
    const curCity = window.location.hostname.split('.')
    let current = curCity[0]
    if (current == 'www') {
      current = curCity[1]
    }
    const founded = this.cities.find(element => element.url == current)
    if (founded) {
      this.$store.commit('setCurrentCity', founded.name)
    }
    window.addEventListener('resize', this.updateWidth);
    this.$bus.$on('smallbasket', (count) => {
      this.countProduct = Number(count)
    });
    this.$bus.$on('compare', (count) => {
      this.countCompare = Number(count)
    })
    this.$bus.$on('favorite', (count) => {
      this.countfavorite = Number(count)
    })
    this.$bus.$on('closeMenu', (count) => {
      this.isMenuOpen = false
    })

    this.baskekeCount();
    var cookieFavorite = this.$cookie.get('favorites');
    if( cookieFavorite ){
      var favoriteJson = JSON.parse(cookieFavorite);
      if( favoriteJson[this.siteid] ){
        for (var key in favoriteJson[this.siteid]) {
          this.countfavorite = this.countfavorite + favoriteJson[this.siteid][key].length;
        }
      }
    }
    var cookieCompare = this.$cookie.get('compare');
    if( cookieCompare ){
      var compareJson = JSON.parse(cookieCompare);
      if( compareJson[this.siteid] ){
        for (var key in compareJson[this.siteid]) {
          this.countCompare = this.countCompare + compareJson[this.siteid][key].length;
        }
      }
    }
    this.$bus.$on('updateSended', () => {
      this.sended=false;
      this.requestcall=false;
    });
    let arVariable = this.$store.state.langs.arVariableSite;
    this.arVariableSite = arVariable.find(v => v.SITE_ID_CITY === this.siteid);
    /*    if( this.mycookie(encodeURIComponent("city")) ) {
          let cookieCityName = this.mycookie(encodeURIComponent("city"));
          this.city = cookieCityName;
          let variable = arVariable.find(v => v.CITY_VIEW === cookieCityName);
          if(variable){
            this.arVariableSite=variable;
          }else{
            this.arVariableSite=arVariable[0];
          }
          return cookieCityName;
        }else{
          this.arVariableSite=arVariable[0];
        }*/
  },
  methods: {
    urls(arCity){
      let hr = location.href;
      return hr.replace(/\/\/.+\.vremyasna/, '//'+arCity.url+'.vremyasna');
    },
    urlCurrent(url){
      return ( location.href == "https://"+this.siteurl+url );
    },
    async baskekeCount() {
      const  countBasketProducts  = (
          await this.$axios({
            url: '/ajax/basketline.php',
            method: 'post',
            params: {
              SITE_ID: this.$store.state.siteid
            }
          })
      )
      if (countBasketProducts) {
        this.countProduct = Number(countBasketProducts.data.count)
      } else {
        console.log('countBasketProducts')
      }
    },
    openSearch() {
      if (this.isSearch) {
        if(this.searchQuery) {
          this.goToSearchResults();
        }
        return
      }
      this.isSearch = true
      setTimeout(() => {
        this.$el.querySelector('.search-button input').focus()
      }, 500)
    },
    closeSearch() {
      this.isSearch = false
      this.searchQuery = ''
      this.suggestions = []
      this.products = []
    },
    updateSearch(value){
      this.searchQuery = value;
      this.onInputSearch();
    },
    async onInputSearch() {
      let stringSearh = this.searchQuery.match(/[^\s]/g);
      if( !stringSearh){ return false; }
      let countSearchQuery = stringSearh.length;
      if(countSearchQuery > 2) {

        clearTimeout(this.tim);

        this.tim = setTimeout(() => {
          let siteId = this.$store.state.siteid;
          this.$axios({
            url: '/ajax/quickSearch.php', method: 'post', data: {search: this.searchQuery, siteId: siteId, mobile: this.isMobile}
          }).then(response => {
            if (response.status == 200) {
              let prodRes = response.data.products;
              if( prodRes ) {
                //this.products = prodRes.slice(0, 3);
                var arrSug = [];
                prodRes.forEach((item) => {
                  arrSug.push( { name:item.NAME, url: "/catalog/"+item.CODE+"/" } );
                });
                this.suggestions = arrSug
              }else{
                //this.products = {}
                this.suggestions = {}
              }
              //this.products = response.data;
            }
          }).catch(e => {
            console.log('Error: ' + e)
            this.loading = false;
          })
        }, 300);
      }else{
        //this.products = {}
        this.suggestions = {}
      }
      //const { products, suggestions } = await this.fakeServer()
      //this.products = products
      // this.suggestions = suggestions

    },
    onSelectSuggestion($event) {
      this.searchQuery = $event
      this.onInputSearch()
    },
    goToSearchResults() {
      window.location.href = `/search/?q=${this.searchQuery}`
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    fakeServer() {
      // Ограничнить вывод максимум 6ю товарами
      return new Promise((resolve, reject) => {
        const fakeProducts = require('./search/products.json')
        const answer = {
          suggestions: [
            'Askona Smart Balance',
            'Askona Another Mattress',
            'Askona Sofa',
            'Askona Chair',
            'Askona Pillows'
          ],
          products: fakeProducts
        }
        resolve(answer)
      })



    },
    changeCity() {
      if (typeof (ym) === "function") {
        let yaCounterId = this.$store.state.langs.YACOUNTER;
        ym(yaCounterId, 'reachGoal', 'contacts_link_click');
      }
      // this.city=cityName;
      // document.cookie = "city=" + encodeURIComponent(cityName) + ";path=/;";
      ////   let arVariable = this.$store.state.langs.arVariableSite;
      ////   this.arVariableSite=arVariable.find(v => v.CITY_VIEW === cityName);
      // logic
      //window.location.href = `/${cityName}`
      // location.reload();
    },
    mycookie(name){
      let matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : false;
    },
    toggle() {
      this.isActive = !this.isActive
    },
    reachGool(goolname){
      if (typeof (ym) === "function") {
        let yaCounterId = this.$store.state.langs.YACOUNTER;
        ym(yaCounterId, 'reachGoal', goolname);
      }
    },
    reachGool2(goolname, allform){
      if (typeof (ym) === "function") {
        let yaCounterId2 = this.$store.state.langs.YACOUNTER2;
        ym(yaCounterId2, 'reachGoal', goolname);
        if(allform) {
          ym(yaCounterId2, 'reachGoal', 'all_forms');
        }
      }
    },
    onValidate(ev){
      this.validPhone = !!ev.isValidByLibPhoneNumberJs;
    },
    sendFeedback( text ) {
      if( !this.validPhone ){
        //this.$notify({group: 'error-form', title: 'error', text: this.$store.state.langs.NOTIFICATION_ERROR_FIELD, type: 'error'});
        return false;
      }
      this.$axios({
        url: '/ajax/?feadback=Y',
        method: 'post',
        params: {
          name: "",
          email: "",
          phone: this.phone,
          text: text,
          url: location.href
        }
      }).then(response => {
        if (response.status == 200 && response.data.success) {
          //console.log( "response", response );
          this.sended = true;
        }else{
          console.log('Error feedback success: false')
        }
      }).catch(e => {
        console.log('Error: ' + e)
      })
      //this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .post-page & {
    position: relative;
  }
}
.phone{
  white-space: nowrap;
}
.requestcall{
  cursor: pointer;
}
.phonetop, .phonetop-mobile{
  display: grid;
  text-align: center;
}
.phonetop{
  margin-right: 20px;
}
.phonetop-mobile{
  white-space: nowrap;
}
.top {
  height: 4.125em;
  background: var(--accent);
  color: #fff;
  padding: 0 var(--gap);
  display: flex;
  align-items: center;
}
.logo-wrapper {
  display: flex;
  align-items: center;
  svg {
    width: 8vw;
    height: auto;
  }
}
.menu {
  margin-right: auto;
  padding: 0;
  list-style: none;
  align-self: stretch;
  align-items: stretch;
  & > * {
    margin-right: 1.25em;
    position: relative;
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.5em;
    }
    &:hover > ul {
      display: block;
    }
    ul {
      position: absolute;
      background: #fff;
      color: var(--accent);
      top: 100%;
      left: 0;
      white-space: nowrap;
      list-style: none;
      border-radius: 5px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: none;
      z-index: 100;
      a {
        padding: 0.5em 1em;
        display: block;
        transition: 0.4s;
        &:hover {
          background: var(--light);
        }
      }
      .item-menu {
        padding: 0.5em 1em;
        display: block;
        transition: 0.4s;
        &:hover {
          background: var(--light);
        }
      }
    }
  }
}
.city {
  margin-right: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  svg {
    margin-right: 0.5em;
  }
  &:hover {
    .cities-popup-block {
      display: block;
    }
  }
}
.cities-popup-block {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 30;
  display: none;
}
.cities-popup {
  background: #fff;
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: var(--color);
  max-height: 300px;
  transform: translate3d(0, 10px, 0);
  .city-name {
    padding: 8px 15px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: var(--light);
    }
    &.active {
      background: var(--color);
      color: #fff;
    }
  }
}
.main {
  padding: 0 var(--gap);
  height: 5em;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 11px 34px rgba(0, 0, 0, 0.06);
  margin-right: 5px;
}
.menu-button,
.logo {
  margin-right: 1.4vw;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 11vw;
  height: auto;
}

.cart-button,
.fav-button,
.side-button {
  margin-left: 1.5vw;
}
.menu-button {
  display: none;
}
.search-button {
  cursor: pointer;
  input {
    width: 100%;
    height: 100%;
    background: none;
    font-size: 15px;
    font-weight: 500;
    font-family: inherit;
    padding: 0 20px;
    margin-right: 10px;
  }
  &.active {
    background: var(--light);
    flex-grow: 1;
    height: 50px;
    border-radius: 7px;
    padding-right: 20px;
    position: relative;
    margin-left: 10px;
  }
}
@media screen and (max-width: 1380px) {
  .main {
    height: 3em;
  }
  .cart-button,
  .side-button,
  .fav-button,
  .search-button {
    svg {
      height: 18px;
      width: auto;
    }
  }
}

@media screen and (max-width: 1170px) {
  .menu-button {
    margin-left: 0.8em;
    display: block;
    svg {
      position: relative;
      top: 2px;
    }
  }
  .search-button {
    margin-right: auto;
  }
}
@media screen and (max-width: 920px) {
  .main-menu {
    display: none;
  }
  .search-button {
    margin-left: auto;
  }
  .main {
    height: 4em;
  }
}
@media screen and (max-width: 767px) {
  .cities {
    margin-left: auto;
    text-align: center;
  }
  .logo {
    width: 8em;
    margin-left: 0.5em;
  }
  .logo-wrapper {
    svg {
      width: 8em;
    }
  }
  .cart-button,
  .fav-button,
  .side-button {
    margin-left: 1em;
  }
  .menu-button {
    margin-left: 0.8em;
    display: block;
  }
  .search-button {
    margin: unset;
    margin-left: auto;
  }
  .layout-header {
    &.is-active-search {
      .logo,
      .buttons {
        display: none !important;
      }
    }
  }
  .main {
    padding: 0;
  }
}
@media screen and (max-width: 320px) {
  .cities{
    font-size: 0.8em;
  }
  .phonetop-mobile {
    a{
      font-size: 0.8em;
    }
  }
  .logo-wrapper {
    svg {
      width: 5em;
    }
  }
}
.items-center {
  align-items: center;
}
</style>
